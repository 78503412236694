import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Navigation from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
import { Container } from 'reactstrap'
import HeroArea from '../components/heroArea/heroarea'
import EventsArea from '../components/eventsArea/eventsArea'
import SupportArea from '../components/supportArea/supportArea'


export default class Home extends Component {
  render () {
    const siteData = this.props.data
    return (
      <Container fluid className='page-container'>
        <Navigation />
        <HeroArea siteData={siteData} />

        <Container fluid>
          <EventsArea siteData={siteData} />

          <SupportArea siteData={siteData} />
        </Container>

        <Container
          fluid
          className='homepage-image homepage-imgage-bottom'
          style={{backgroundImage: `url(${siteData.allWordpressWpMedia.edges.filter(({ node }) =>
            node.title.includes('second-main-page-image')
          )[0].node.source_url})`}}
        />

        <Footer />
      </Container>
    )
  }
}

export const query = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          title
          date
          slug
          polylang_current_lang
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          id
          title
          date
          slug
          polylang_current_lang
          categories {
            name
          }
        }
      }
    }
    allWordpressWpMedia {
      edges {
        node {
          id
          title
          source_url
          localFile {
            id
            absolutePath
          }
        }
      }
    }
  }
`

