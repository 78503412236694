import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Col, Row } from 'reactstrap'


export default ({col1, col2}) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpMedia {
          edges {
            node {
              id
              title
              source_url
              localFile {
                id
                absolutePath
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Row style={{ margin: '0 auto' }} noGutters>
        <Col
          xs={{ size: 4 }}
          sm={{ size: 2 }}
          md={{ size: 2 }}
          lg={{ size: 2 }}
          {...col1}
        >
          <a href="http://www.ngo.ee/annetamine" target="_blank" className="image">
            <img
              className="image-box"
              src={
                data.allWordpressWpMedia.edges.filter(({ node }) =>
                  node.source_url.includes('/2014/09/koguja.png')
                )[0].node.source_url
              }
            />
          </a>
        </Col>
        <Col
          xs={{ size: 6, offset: 1 }}
          sm={{ size: 4, offset: 1 }}
          md={{ size: 4, offset: 1 }}
          lg={{ size: 2, offset: 2 }}
          xl={{ size: 2, offset: 1 }}
          {...col2}
        >
          <a
            href="https://www.armastanaidata.ee/annetan/tervis-ja-toimetulek/tallinna-ja-harjumaa-autismiuhing-toetame-peredele-vajalikke-tugigruppe/"
            target="_blank"
            className="image"
          >
            <img
              className="image-box"
              src={
                data.allWordpressWpMedia.edges.filter(({ node }) =>
                  node.source_url.includes('/2018/12/logo-2.png')
                )[0].node.source_url
              }
            />
          </a>
        </Col>
      </Row>
    )}
  />
)
