import React  from 'react'
import languageService from '../../services/language-service'


const VideoButton = () => (
  <div className="video-button">
    <a className='play-link' href='https://youtu.be/eohg_DkHg7E'>
      <div className="play-icon">
        <div className="play-icon-circle">
          <div className="play-icon-triangle" />
        </div>
      </div>
      <div className="video-button-text-area">
        <div className="text-container">
          <div className="video-button-txt">
            {languageService.getVideoBtnText()}
          </div>
          <div className="video-name">
            &ldquo;Mis on autism&rdquo;
          </div>
        </div>
      </div>
    </a>
  </div>
)

export default VideoButton
