import React from 'react'
import languageService from '../../services/language-service'
import SupportImages from './supportImages'
import {Row, Col} from 'reactstrap'


const SupportArea = ({ siteData }) => (
  <Row>
    <Row className='support-area' style={{margin: '0 auto'}}>
      <Col lg='6' xs='12' className='feed-box'>
        <h3 className='black-text text-subtitle'>{languageService.getSupportTitle()}</h3>
        <p>{languageService.getSupportText()}</p>
        <SupportImages />
      </Col>
      <Col lg='6' xs='12' className='feed-box'>
        <h3 className='black-text text-subtitle'>{languageService.getPartnersTitle()}</h3>
        <p>{languageService.getPartnersText()}</p>
        <Row className='pt-4'>
          <Col xs="12" sm="4" className='mb-4'>
            <a href='http://catapultlabs.eu/' target='_blank' className='image' style={{margin: '0 auto'}}>
              <img
                className='credits-logo'
                src={
                  siteData.allWordpressWpMedia.edges.filter(({ node }) =>
                    node.source_url.includes('/2018/12/catapult-500x117.png')
                  )[0].node.source_url
                }
              />
            </a>
          </Col>
          <Col xs="12" sm="4" className='mb-4 pl-0'>
            <a href='https://www.ut.ee/et' target='_blank' className='image pl-0' style={{margin: '0 auto'}}>
              <img
                className='credits-logo'
                src={
                  siteData.allWordpressWpMedia.edges.filter(({ node }) =>
                    node.source_url.includes(
                      'TÜ_logod_17122015_horisontaal_est_sinine-300x53.png'
                    )
                  )[0].node.source_url
                }
              />
            </a>
          </Col>
          <Col sm="4" className='mb-4'>
            <a href='https://www.nope.ee' target='_blank' className='image' style={{margin: '0 auto'}}>
              <img
                className='credits-logo'
                src={
                  siteData.allWordpressWpMedia.edges.filter(({ node }) =>
                    node.source_url.includes('/2018/12/nope_logo.png')
                  )[0].node.source_url
                }
              />
            </a>
          </Col>
          <Col sm="4" className='mb-4'>
            <a href='https://tallinnakoda.ee/' target='_blank' className='image' style={{margin: '0 auto'}}>
              <img
                className='credits-logo'
                style={{maxHeight: '40px'}}
                src={
                  siteData.allWordpressWpMedia.edges.filter(({ node }) =>
                    node.source_url.includes('/2019/03/TPIK-logo.jpg')
                  )[0].node.source_url
                }
              />
            </a>
          </Col>
          <Col sm="4" className='mb-4 pl-0'>
            <a href='https://www.autismeesti.ee/' target='_blank' className='image' style={{margin: '0 auto'}}>
              <img
                className='credits-logo'
                style={{maxHeight: '40px'}}
                src={
                  siteData.allWordpressWpMedia.edges.filter(({ node }) =>
                    node.source_url.includes('/2019/03/EAU_uuendatud_logo_v2ike.png')
                  )[0].node.source_url
                }
              />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  </Row>
)

export default SupportArea
