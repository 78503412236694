import React from 'react'
import languageService from '../../services/language-service'
import { Link } from 'gatsby'
import { Col, ListGroup, Row } from 'reactstrap'
import postService from '../../services/post-service'
import './eventsArea.css'


const EventsArea = ({ siteData }) => (
  <Row className='event-area-background'>
    <Row className='event-area'>
      <Col lg='6' xs='12' className='feed-box orange-background'>
        <h2 className='feed-title purple-text'>
          {languageService.getEventsFeedTitle().trim()}
        </h2>
        <ListGroup flush className='calendar-list-group'>
          {renderPostLinks(siteData.allWordpressPost, 'Uudis')}
        </ListGroup>
        <Link className='eventsArchiveButton' to='/uudised'>
          {languageService.getSeeAllEventsLabel()}
        </Link>
      </Col>
      <Col lg='6' xs='12' className='feed-box green-background'>
        <h2 className='feed-title purple-text'>{languageService.getSupportGroupsTitle()}</h2>
        <ListGroup flush className='calendar-list-group'>
          {renderPostLinks(siteData.allWordpressPost, 'Tugigrupp')}
        </ListGroup>
        <Link className='supportGroupsButton' to='/tugigrupid'>
          {languageService.getAllSupportGroupsLabel()}
        </Link>
      </Col>
    </Row>
  </Row>
)

const renderPostLinks = (posts, category, numberOfPostsInFeed = 3) =>
  postService.renderNPostsFromCategory(posts, category, numberOfPostsInFeed)

export default EventsArea
