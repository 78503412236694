import React from 'react'
import { Jumbotron } from 'reactstrap'
import VideoButton from './videoButton'
import './heroarea.css'

const HeroArea = ({siteData}) => (
  <Jumbotron
    className='homepage-image homepage-imgage-top nav-bar-spacing'
    style={{backgroundImage: `url(${siteData.allWordpressWpMedia.edges.filter(({ node }) =>
        node.title.includes('first-main-page-image')
      )[0].node.source_url})`}}
  >
    <div className='jumbotron-content'>
      <div className='jumbotron-text'>
        <h1 className='text-title white-text'>
          Teejuht
          <br />
          autismispektris
        </h1>
        <VideoButton />
      </div>
    </div>
  </Jumbotron>
)

export default HeroArea
